@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
  font-family: ;
}
html,
body {
  /* overflow-x: hidden; */
  font-family: "Noto Sans";
}
body {
  background-color: #f9f9f9;
  position: relative;
  /* overflow-x: hidden; */
}
.swiper-wrapper {
  display: flex;
  align-items: flex-end !important;
}
.strike {
  text-decoration: none;
}
.custom-loader {
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
